<template>
    <div class="operationPromotion-container">
        <el-scrollbar style="height: 100%;">
            <div class="promotion-txt" style="padding-left: 20px;">
                <span>广告数据</span>
            </div>
            <div class="promotion-table">
                <el-tabs v-model="activeName" @tab-click="handleClick">
                    <el-tab-pane label="搜索广告" name="first">
                        <AdsSearch ref="ads"></AdsSearch>
                    </el-tab-pane>
                    <el-tab-pane label="热门关键词" name="second">
                        <HotKeyWord ref="keyword"></HotKeyWord>
                    </el-tab-pane>
                </el-tabs>
            </div>
        </el-scrollbar>
    </div>
</template>

<script>
    import AdsSearch from "@/components/student/operationPromotion/AdsSearch";
    import HotKeyWord from "@/components/student/operationPromotion/HotKeyWord";
    export default {
        name: "dataAnalysis",
        components:{
            AdsSearch,
            HotKeyWord
        },
        data() {
            return {
                activeName: 'first',
                amount: null,
            };
        },
        methods: {
            upateAmount(val) {
                //更新余额
                this.amount = val;
            },
            handleClick(val) {
                switch (val.name) {
                    case "first":
                        this.$refs.ads.getAreaList();
                        break;
                    case "second":
                        this.$refs.keyword.getHotKeywordsList();
                        break;
                    //     this.$refs.keyword.getKeywordManageList();
                    //     this.$refs.keyword.getPlanNameList();
                    //     break;
                    // case "third":
                    //     this.$refs.crowd.getCrowdList();
                    //     this.$refs.crowd.getPlanNameList();
                    //     break;
                }
            },
            getAmout() {

            },
        }
    }
</script>

<style scoped lang="scss">
    .student-breadcrumb {
        font-size: 14px;
    }

    .operationPromotion-container {
        display: flex;
        flex-direction: column;
        height: 100vh;
        box-sizing: border-box;

        .title {
            font-size: 20px;
            font-weight: 500;
            color: #333333;
            margin: 20px 0;
            line-height: 1;
        }

        .promotion-table {
            min-height: calc(100vh - 195px);
            width: 100%;

            ::v-deep .el-tabs {
                min-height: calc(100vh - 215px);
                background-color: #fff;

                .el-tabs__content {
                    min-height: calc(100vh - 299px);

                    .el-tab-pane {
                        min-height: calc(100vh - 299px);
                    }
                }

                .el-tabs__header {
                    padding: 20px;

                    .el-tabs__nav-wrap::after {
                        height: 0px;
                    }

                    .el-tabs__item {
                        font-size: 20px;
                        font-weight: 400;
                        color: #333333;
                    }

                    .el-tabs__item {
                        line-height: 29px;
                    }

                    .el-tabs__nav .is-active {
                        color: #FD4446;
                    }

                    .el-tabs__active-bar {
                        background-color: #FD4446;
                    }
                }
            }
        }
    }

    .promotion-txt {
        display: flex;
        justify-content: space-between;
        color: #333333;
        font-size: 28px;
        font-weight: bold;
        line-height: 1;
        margin: 20px 0;

        span:nth-child(2) {
            font-size: 18px;
            color: #FD4446;
            font-weight: 500;
        }

        b {
            font-size: 12px;
            margin: 0 2px;
            font-weight: 500;
        }
    }
</style>
